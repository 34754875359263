import React, { Component } from "react";
import "./styles/Main.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Homepage from "./pages/Homepage/Homepage";
import Contents from "./pages/Contents/Contents";
import TeamIntro from "./pages/Contents/TeamIntro";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import TheLegendContainer from "./pages/TheLegend/TheLegendContainer";
import EpisodeContainer from "./pages/TheLegend/episodes/EpisodeContainer";
import StoreContainer from "./pages/store/StoreContainer";
import LoginContainer from "./pages/auth/LoginContainer";
// import NoMatch from "./pages/NoMatch";

export default class App extends Component {
    render() {
        return (
            <div className="app">
                <Router>
                    <div className="app-wrapper">
                        <Routes>
                            <Route path="/" element={<Homepage />} />

                            <Route path="/contents" element={<Contents />} />

                            <Route path="/soon" element={<ComingSoon />} />

                            <Route
                                path="/episodes"
                                element={<TheLegendContainer />}
                            />

                            <Route
                                path="/episode"
                                element={<EpisodeContainer />}
                            />

                            <Route
                                path="/contents/team-intro"
                                element={<TeamIntro />}
                            />

                            <Route path="/store" element={<StoreContainer />} />

                            <Route path="/auth" element={<LoginContainer />} />

                            {/* <Route path="*" element={<NoMatch />} /> */}
                        </Routes>
                    </div>
                </Router>
            </div>
        );
    }
}

import React from "react";
interface Props {}

export default function TeamIntro({}: Props): React.ReactElement<Props> {
    const xIcon = "X";
    const telegramIcon = "TG";
    return (
        <div className="team-intro-section-wrapper">
            <div className="team-intro-section">
                <div className="title-wrapper">
                    <h2 className="title">Team Introduction</h2>
                </div>

                <div className="content-wrapper">
                    <div className="content">
                        <p className="content-description">
                            GM Punks! We are the AzeroPunks Devs; A small,
                            community lead team consisting of five developers,
                            working together for over two years delivering,
                            assisting, and advising multiple projects from
                            Fintech, Metaverse and of course NFTs.
                        </p>

                        {/* <svg
                            className="bd-placeholder-img rounded-circle"
                            width="140"
                            height="140"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-label="Placeholder"
                            preserveAspectRatio="xMidYMid slice"
                            focusable="false"
                        >
                            <title>Panjea</title>
                            <rect
                                width="100%"
                                height="100%"
                                fill="var(--bs-secondary-color)"
                            ></rect>
                        </svg> */}

                        <div className="team-punks-wapper">
                            <div className="team-punks">
                                <div className="team-punk-img">
                                    <svg
                                        className="bd-placeholder-img rounded-circle"
                                        width="140"
                                        height="140"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYmid slice"
                                        focusable="false"
                                    >
                                        <title>Bert</title>
                                        <rect
                                            width="100%"
                                            height="100%"
                                            fill="var(--bs-secondary-color)"
                                        ></rect>
                                    </svg>
                                    <div className="team-punk-name">Bert</div>
                                    <div className="team-punk-role">
                                        Artist & Storyteller
                                    </div>
                                    <div className="team-punk-social">
                                        {xIcon}@BertThaFarmer
                                    </div>
                                </div>
                            </div>
                            <div className="team-punks">
                                <div className="team-punk-img">
                                    <svg
                                        className="bd-placeholder-img rounded-circle"
                                        width="140"
                                        height="140"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYmid slice"
                                        focusable="false"
                                    >
                                        <title>Oleg</title>
                                        <rect
                                            width="100%"
                                            height="100%"
                                            fill="var(--bs-secondary-color)"
                                        ></rect>
                                    </svg>
                                    <div className="team-punk-name">Oleg</div>
                                    <div className="team-punk-role">
                                        Web Developer
                                    </div>
                                    <div className="team-punk-social">
                                        {telegramIcon}@waterburner
                                    </div>
                                </div>
                            </div>
                            <div className="team-punks">
                                <div className="team-punk-img">
                                    <svg
                                        className="bd-placeholder-img rounded-circle"
                                        width="140"
                                        height="140"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYmid slice"
                                        focusable="false"
                                    >
                                        <title>J</title>
                                        <rect
                                            width="100%"
                                            height="100%"
                                            fill="var(--bs-secondary-color)"
                                        ></rect>
                                    </svg>
                                    <div className="team-punk-name">J</div>
                                    <div className="team-punk-role">
                                        Full Stack Dev
                                    </div>
                                    <div className="team-punk-social">
                                        {xIcon}@ThaFomoChicken
                                    </div>
                                </div>
                            </div>
                            <div className="team-punks">
                                <div className="team-punk-img">
                                    <svg
                                        className="bd-placeholder-img rounded-circle"
                                        width="140"
                                        height="140"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYmid slice"
                                        focusable="false"
                                    >
                                        <title>Michiel</title>
                                        <rect
                                            width="100%"
                                            height="100%"
                                            fill="var(--bs-secondary-color)"
                                        ></rect>
                                    </svg>
                                    <div className="team-punk-name">
                                        Michiel
                                    </div>
                                    <div className="team-punk-role">
                                        Smart Contract Dev
                                    </div>
                                    <div className="team-punk-social">
                                        {telegramIcon}@michielcrypto
                                    </div>
                                </div>
                            </div>
                            <div className="team-punks">
                                <div className="team-punk-img">
                                    <svg
                                        className="bd-placeholder-img rounded-circle"
                                        width="140"
                                        height="140"
                                        xmlns="http://www.w3.org/2000/svg"
                                        role="img"
                                        aria-label="Placeholder"
                                        preserveAspectRatio="xMidYmid slice"
                                        focusable="false"
                                    >
                                        <title>Casey</title>
                                        <rect
                                            width="100%"
                                            height="100%"
                                            fill="var(--bs-secondary-color)"
                                        ></rect>
                                    </svg>
                                    <div className="team-punk-name">Casey</div>
                                    <div className="team-punk-role">
                                        Community Lead
                                    </div>
                                    <div className="team-punk-social">
                                        {xIcon}@casey__francis
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";

import TheLegendHeadNavbar from "./TheLegendHeadNavbar";

import Footer from "../../Footer";
import TheLegendTitle from "../../img/TheLegend/TheLegendTransparent.png";
interface Props {}

export default function TheLegendContainer({}: Props): React.ReactElement<Props> {
    return (
        <>
            <div className="the-legend-container">
                <div className="the-legend-container-wrapper">
                    <TheLegendHeadNavbar />

                    <div className="the-legend-title-wrapper">
                        <img
                            src={TheLegendTitle}
                            alt=""
                            className="the-legend-title-img"
                        />
                    </div>

                    <div className="episodes-contents-navigation">
                        <h4 className="episodes-contents-navigation-title">
                            Episodes:
                        </h4>
                        <ul className="episodes-contents-navigation-list">
                            <li className="episodes-contents-item">
                                Episode 1: "Name of the episode"
                            </li>
                            <li className="episodes-contents-item">
                                Episode 2 coming soon!
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

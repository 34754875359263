import React from "react";

interface Props {}

export default function Homepage({}: Props): React.ReactElement<Props> {
    return (
        <div className="contents-wrapper">
            <div className="contents">
                <div className="title-wrapper">
                    <h2 className="title">Contents</h2>
                </div>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="section about-us">
                            <h3 className="section-subtitle">About Us</h3>
                            <ul className="section-list">
                                <li>1.0 Team Introduction</li>
                                <li>1.1 Project Backstory</li>
                            </ul>
                        </div>

                        <div className="section collection-breakdown">
                            <h3 className="section-subtitle">
                                Collection Breakdown
                            </h3>
                            <ul className="section-list">
                                <li>2.0 Collection Breakdown</li>
                                <li>2.1 Clans</li>
                                <li>2.2 Characters</li>
                                <li>2.3 Traits</li>
                            </ul>
                        </div>

                        <div className="section the-legend">
                            <h3 className="section-subtitle">
                                The Legend Of Scatter City
                            </h3>
                            <ul className="section-list">
                                <li>3.0 AzeroPunks Presents: TLOSC</li>
                                <li>3.1 Introductions</li>
                                <li>3.2 Community Driven; Token Gated</li>
                                <li>Staking</li>
                                <li>Silver Rewards</li>
                            </ul>
                        </div>

                        <div className="section misc">
                            <h3 className="section-subtitle">Misc</h3>
                            <ul className="section-list">
                                <li>4.0 Launchpad</li>
                                <li>4.1 Launchpad Fees</li>
                                <li>5.0 Links & Social Media</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

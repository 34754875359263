import React from "react";
interface Props {}

export default function ComingSoon({}: Props): React.ReactElement<Props> {
    return (
        <div className="coming-soon-section">
            <div className="coming-soon-wrapper">
                <h2 className="section-subtitle">
                    Soon only at CryptoPunks.com
                </h2>
                <div className="teaser"></div>
            </div>
        </div>
    );
}

// BASIC TEMPORARY VERSION

import "bootstrap/dist/css/bootstrap.css";
import "../../scss/styles.scss";

import HowToEarnSilver from "./HowToEarnSliver";
import React from "react";
import discordLogo from "../../img/discord-logo.svg";
import punksLogo from "../../img/punks-logo.png";
import telegramLogo from "../../img/telegram-logo.svg";
import twitterLogo from "../../img/twitter-logo.png";

// import Partners from "./Partners"; // Partners is not used (commented out)
// import Raffle from "./Raffle"; // Ruffle is not used (commented out)

interface Props {}

export default function Homepage({}: Props): React.ReactElement<Props> {
    return (
        <div className="homepage">
            <div className="homepage-wrapper">
                <div className="bg-overlay pt-5">
                    <div className="px-5">
                        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
                            <div className="col-md-3 mb-2 mb-md-0">
                                <span className="d-inline-flex link-body-emphasis text-decoration-none">
                                    <img
                                        className="nav-logo"
                                        src={punksLogo}
                                        alt="punks logo"
                                    />
                                </span>
                            </div>

                            <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                                <li>
                                    <a
                                        href="https://portal.azeropunks.com"
                                        className="link"
                                    >
                                        <span className="nav-link px-2 link-secondary text-white">
                                            Home
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://a0.artzero.io/collection/5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT?is_for_sale=true">
                                        <span className="nav-link px-2 text-white">
                                            Marketplace
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://portal.azeropunks.com/">
                                        <span className="nav-link px-2 text-white">
                                            My Account
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    {/* <a href="/contents">
                                        <span className="nav-link px-2 text-white">
                                            Contents
                                        </span>
                                    </a> */}
                                    <a
                                        href={require("../../resources/WP2.0.pdf")}
                                        target="_blank"
                                    >
                                        <span className="nav-link px-2 text-white">
                                            Whitepaper
                                        </span>
                                    </a>
                                </li>
                            </ul>

                            <div className="col-md-3 text-end">
                                <a href="https://portal.azeropunks.com/">
                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                    >
                                        Connect Wallet
                                    </button>
                                </a>
                            </div>
                        </header>
                    </div>
                    <div className="d-flex align-items-center justify-content-center hero-text-position">
                        <div className="pb-5 text-center join-punks-title">
                            <div className="container d-flex flex-column align-items-center justify-content-center">
                                <h1 className="text-white pb-3 landing-header">
                                    Join{" "}
                                    <span className="text-primary">5,555 </span>
                                    punks
                                    <br /> fighting for decentralisation
                                </h1>
                                {/* <button
                                    className="btn btn-primary px-5 mb-5 hidden"
                                    type="button"
                                >
                                    Mint Now
                                </button> */}
                            </div>

                            <h1 className="coming-soon">
                                The Legend of Scatter City is coming soon!
                            </h1>
                        </div>
                    </div>
                </div>
                <HowToEarnSilver />
            </div>

            {/* <Raffle /> */}

            {/* <Partners /> */}
            <div className="bg-spotlight social-section">
                <div className="container my-5 py-5">
                    <h1 className="text-center my-5 text-white">
                        Join Our Community
                    </h1>
                    <div className="row social-links">
                        <div className="col-lg-4 text-center">
                            <a
                                href="https://twitter.com/PunksAzero"
                                className="social-links"
                            >
                                <svg
                                    className="bd-placeholder-img rounded-circle"
                                    width="140"
                                    height="140"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                    aria-label="Placeholder"
                                    preserveAspectRatio="xMidYMid slice"
                                    focusable="false"
                                >
                                    <title>X (Twitter)</title>
                                    <image
                                        href={twitterLogo}
                                        height="140"
                                        width="140"
                                    />
                                </svg>
                                <h2 className="fw-normal py-4 text-white">
                                    X (Twitter)
                                </h2>
                            </a>
                        </div>

                        <div className="col-lg-4 text-center">
                            <a
                                href="https://discord.gg/QZqDxfZQMp"
                                className="social-links"
                            >
                                <svg
                                    className="bd-placeholder-img rounded-circle"
                                    width="140"
                                    height="140"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                    aria-label="Placeholder"
                                    preserveAspectRatio="xMidYMid slice"
                                    focusable="false"
                                >
                                    <image
                                        href={discordLogo}
                                        height="140"
                                        width="140"
                                    />
                                    <title>Discord</title>
                                </svg>
                                <h2 className="fw-normal py-4 text-white">
                                    Discord
                                </h2>
                            </a>
                        </div>
                        <div className="col-lg-4 text-center">
                            <a
                                href="https://t.me/AzeroPunks"
                                className="social-links"
                            >
                                <svg
                                    className="bd-placeholder-img rounded-circle"
                                    width="140"
                                    height="140"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                    aria-label="Placeholder"
                                    preserveAspectRatio="xMidYMid slice"
                                    focusable="false"
                                >
                                    <image
                                        href={telegramLogo}
                                        height="140"
                                        width="140"
                                    />
                                    <title>Telegram</title>
                                </svg>
                                <h2 className="fw-normal py-4 text-white">
                                    Telegram
                                </h2>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3 px-5 footer">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 footer-wrapper">
                    <p className="col-md-4 mb-0 text-white">
                        © 2023 Azero Punks
                    </p>

                    <span className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <img
                            className="nav-logo"
                            src={punksLogo}
                            alt="punks logo"
                        />
                    </span>

                    <ul className="nav col-md-4 justify-content-end">
                        <li className="nav-item">
                            <a
                                href="https://twitter.com/PunksAzero"
                                className="footer-social-link"
                            >
                                <span className="px-2 text-white text-decoration-none">
                                    X (Twitter)
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://t.me/AzeroPunks"
                                className="footer-social-link"
                            >
                                <span className="px-2 text-white text-decoration-none">
                                    Telegram
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://discord.gg/QZqDxfZQMp"
                                className="footer-social-link"
                            >
                                <span className="px-2 text-white text-decoration-none">
                                    Discord
                                </span>
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        </div>
    );
}

import React from "react";

import punksLogo from "../../img/punks-logo.png";

export default function Header() {
    return (
        <div className="store-header">
            <div className="store-header-wrapper">
                <div className="store-header-left">
                    <div className="header-logo-wrapper">
                        <div className="header-logo">
                            <a href="http://azeropunks.com" className="link">
                                <img
                                    className="nav-logo"
                                    src={punksLogo}
                                    alt="punks logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="store-header-right store-nav-menu-wrapper">
                    <ul className="store-nav-menu">
                        <a href="http://azeropunks.com" className="link">
                            <li className="store-nav-menu-item">Home</li>
                        </a>
                        <a
                            href="http://a0.artzero.io/collection/5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT?is_for_sale=true"
                            className="link"
                        >
                            <li className="store-nav-menu-item">Marketplace</li>
                        </a>
                        <a href="#" className="link">
                            <li className="store-nav-menu-item">My Account</li>
                        </a>
                        <a
                            href={require("../../resources/WP.pdf")}
                            className="link"
                        >
                            <li className="store-nav-menu-item">Whitepaper</li>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import punksLogo from "../../img/punks-logo.png";

interface Props {}

export default function TheLegendHeadNavbar({}: Props): React.ReactElement<Props> {
    return (
        <div className="the-legend-head-navbar">
            <div className="the-legend-head-navbar-wrapper">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
                    <div className="col-md-3 mb-2 mb-md-0 desktop-only">
                        <span className="d-inline-flex link-body-emphasis text-decoration-none">
                            <img
                                className="nav-logo"
                                src={punksLogo}
                                alt="punks logo"
                            />
                        </span>
                    </div>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li>
                            <a href="http://azeropunks.com" className="link">
                                <span className="nav-link px-2 link-secondary text-white">
                                    Home
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="https://a0.artzero.io/collection/5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT?is_for_sale=true">
                                <span className="nav-link px-2 text-white">
                                    Marketplace
                                </span>
                            </a>
                        </li>
                        <li className="desktop-only">
                            <a href="https://portal.azeropunks.com/">
                                <span className="nav-link px-2 text-white">
                                    My Account
                                </span>
                            </a>
                        </li>

                        <li>
                            {/* <a href="/contents">
                                        <span className="nav-link px-2 text-white">
                                            Contents
                                        </span>
                                    </a> */}
                            <a
                                href={require("../../resources/WP.pdf")}
                                target="_blank"
                            >
                                <span className="nav-link px-2 text-white">
                                    Whitepaper
                                </span>
                            </a>
                        </li>
                    </ul>

                    <div className="col-md-3 text-end desktop-only">
                        <a href="https://portal.azeropunks.com/">
                            <button
                                type="button"
                                className="btn btn-primary me-2"
                            >
                                Connect Wallet
                            </button>
                        </a>
                    </div>
                </header>
            </div>
        </div>
    );
}

import { useEffect, useState } from "react";
import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import { WsProvider, ApiPromise } from "@polkadot/api";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";

import ReactLoading from "react-loading";

import FooterBootstrap from "../../footer/FooterBootstrap";

// import punksLogo from "../../img/punks-logo.png";

import Header from "../header-footer/Header";

const wsUrl = "wss://ws.azero.dev";

const urlBase = "https://azeropunks-staking.azurewebsites.net";

const NAME = "AzeroPunksRock!";

// const fetchFunction = async () => {
//     const proxyUrl = "https://cors-anywhere.herokuapp.com/";
//     const targetUrl = "https://example.com/api/data";

//     const response = await fetch(proxyUrl + targetUrl);
//     return response.json();
// };

export default function StoreContainer() {
    const [loading, setLoading] = useState(false);
    const [api, setApi] = useState<ApiPromise>();
    const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
    const [selectedAccount, setSelectedAccount] =
        useState<InjectedAccountWithMeta>();

    const setup = async () => {
        const wsProvider = new WsProvider(wsUrl);

        const api = await ApiPromise.create({
            provider: wsProvider,
        });
    };
    const handleConnection = async () => {
        const extensions = await web3Enable(NAME);

        if (!extensions) {
            throw Error("no extensions found");
        }

        const allAccounts = await web3Accounts();

        setAccounts(allAccounts);

        if (allAccounts.length === 1) {
            setSelectedAccount(allAccounts[0]);
            // return;
        }

        apiProductInfo();
    };

    // ================= START OF TESTING =================

    const loadData = () => {
        setDataApiInfo({
            prodCode: "punkreward",
            name: "The first punk reward!",
            price: 150,

            maxBuy: 1,
            available: 100,
        });
        setUserBalance({
            address: "addressaddressaddressaddress",
            stakeCount: 350,
        });
    };

    // ================= END OF TESTING =================

    useEffect(() => {
        setup();
    }, []);

    useEffect(() => {
        if (!api) return;

        (async () => {
            const time = await api.query.timestamp.new();

            console.log(time.toHuman());
            // console.log(time.toPrimitive()); // Polkadot uses toPrimitive
        })();
    }, [api]);

    interface productInfoData {
        prodCode?: string;
        name?: string;
        price?: number;
        maxBuy?: number;
        available?: number;
    }

    const [dataApiInfo, setDataApiInfo] = useState<productInfoData>();
    // const [dataApiInfo, setDataApiInfo] = useState<productInfoData>({ //for testing
    //     prodCode: "punkreward",
    //     name: "Azero Punks mini reward pack",
    //     price: 150,
    //     maxBuy: 1,
    //     available: 100,
    // });

    interface productViewData {
        productCode: string;
        product: {
            prodCode: string;
            name: string;
            price: number;
            maxBuy: number;
            available: number;
        };
        code: string;
        usedBy: string;
        usedDate: string;
    }

    const [dataApiView, setDataApiView] = useState<productViewData>();

    interface userBalanceData {
        address?: string;
        stakeCount?: number;
    }

    const [userBalance, setUserBalance] = useState<userBalanceData>();

    interface buyNewCodeData {
        type?: any;
        title?: any;
        status?: number;
        errors?: any;
        detail?: string;
        traceId?: string;
        prodCode?: any;
        product?: any;
        code?: any;
        details?: string;
        usedBy?: any;
        usedDate?: any;
    }

    const [newCodeData, setNewCodeData] = useState<buyNewCodeData>();
    // const [newCodeData, setNewCodeData] = useState<buyNewCodeData>({ //for testing
    //     prodCode: "punkreward",
    //     product: "product",
    //     code: "CODE-CODE-CODE",
    //     usedBy: "today",
    //     usedDate: "usedDate",
    // });

    const addressShortened = (longAddress?: any) => {
        if (longAddress === undefined) {
            return null;
        }
        if (longAddress.length > 31) {
            return (
                longAddress.substr(0, 13) +
                "..." +
                longAddress.substr(longAddress.length - 13, longAddress.length)
            );
        }
        return longAddress;
    };

    const connectedAddress = selectedAccount?.address;
    const connectedAddressDisplay = addressShortened(connectedAddress);

    const balance = userBalance?.stakeCount; //fetch from apiUserData
    const balanceDisplay = `${balance} silver`;

    // const itemName = dataApiInfo?.name;
    // const itemNameDisplay = `${itemName}`;

    // const itemPrice = dataApiInfo?.price;
    // const itemPriceDisplay = `${itemPrice} silver`;

    // const perUser = dataApiInfo?.maxBuy;
    // const perUserDisplay = `${perUser} available for You`;

    // const availableNumber = dataApiInfo?.available;
    // const availableNumberDisplay = `${availableNumber} codes`;

    const discountCode = newCodeData?.code;
    const discountCodeDisplay = `${discountCode}`;

    const userCode = dataApiView?.code;
    // const codeExpiration = dataApiView?.usedBy;
    // const codePurchaseDate = dataApiView?.usedDate;

    // fetch

    const apiProductView = (
        address: any = selectedAccount?.address,
        prodCode: string = "punkreward"
    ) => {
        const urlView = `${urlBase}/api/Product/view?prodCode=${prodCode}&address=${address}`;

        fetch(urlView, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Host: "azeropunks-staking.azurewebsites.net",
            },
        })
            .then((response) => {
                console.log("response:", response);

                if (response.status === 204) {
                    console.log(
                        "apiProductView Response - no content",
                        response
                    );

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log("!response.ok", response);
                    console.log(
                        "apiProductView Response Status:",
                        response.status
                    );
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((fetchedData: any[]) => {
                if (fetchedData.length > 0) {
                    const firstItem = fetchedData[0]; // Access the first element of the array
                    setDataApiView({
                        productCode: firstItem.productCode,
                        product: {
                            prodCode: firstItem.product.prodCode,
                            name: firstItem.product.name,
                            price: firstItem.product.price,
                            maxBuy: firstItem.product.maxBuy,
                            available: firstItem.product.available,
                        },
                        code: firstItem.code,
                        usedBy: firstItem.usedBy,
                        usedDate: firstItem.usedDate,
                    });
                    console.log("apiProductView: fetched productViewData");
                } else {
                    console.log("No data received from the API");
                }
            })
            .catch((error) => {
                console.log("apiProductView CATCHed an error", error);
            });
    };

    // TO DO
    // apiUserData

    const apiUserBalance = (userAddress: any) => {
        setLoading(true);

        const urlUserData = `${urlBase}/Staking/balance?address=${userAddress}`;
        // const urlUserData = `https://azeropunks-staking.azurewebsites.net/Staking/balance?address=5DLq5iqTyPCn6WJMTB97aJYNXFCWGEqTevPaE5Ts1iv2uJaL`;
        console.log("apiUserBalance userAddress", userAddress);
        console.log("apiUserBalance userAddress", userAddress);
        console.log("apiUserBalance userAddress", userAddress);
        console.log("apiUserBalance userAddress", userAddress);

        console.log("urlUserData (the API)", urlUserData);

        fetch(urlUserData, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Host: "azeropunks-staking.azurewebsites.net",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log(
                        "apiUserData didn't return data",
                        response.status
                    );
                }

                if (!response.ok) {
                    console.log(
                        "Something went wrong. Status:",
                        response.status
                    );
                    throw new Error("Network response was not ok");
                }
                // Handle unexpected end of JSON input
                try {
                    return response.json();
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    throw new Error("Failed to parse JSON response");
                }
            })
            .then((fetchedData: any) => {
                if (Object.keys(fetchedData).length > 0) {
                    setUserBalance({
                        address: fetchedData.address,
                        stakeCount: fetchedData.stakeCount,
                    });
                } else {
                    console.log(fetchedData);
                    console.log("apiUserBalance failed Fetch (no data)");

                    return null;
                }
            })
            .catch((error) => {
                console.log("apiUserBalance error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // apiProductInfoData
    // const apiProductInfo = (selectedAddress: string) => {
    const apiProductInfo = () => {
        const urlInfo = `${urlBase}/api/Product/info?prodCode=punkreward`;

        fetch(urlInfo, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log("Response status 204");

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log("network response was not ok");
                    console.log("Response Status:", response.status);
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((fetchedData: any) => {
                setDataApiInfo({
                    prodCode: fetchedData.prodCode, // "prodCode":"punkreward",
                    name: fetchedData.name, // "name":"The first punk reward!",
                    price: fetchedData.price, // "price":150,

                    maxBuy: fetchedData.maxBuy, // "maxBuy":1,
                    available: fetchedData.available, // "available":100
                });
                console.log("fetched productInfoData");
            })
            .catch((error) => {
                console.log("apiProductInfo CATCHed an error", error);
            });
    };

    // end fetch

    useEffect(() => {
        apiUserBalance(selectedAccount?.address);

        // Call other functions that depend on selectedAccount here
    }, [selectedAccount]);

    // TO DO
    // buy code function

    const buyDiscountCodeWithSilver = (address: any, prodCode: any) => {
        if (address === undefined) {
            handleConnection();
        }

        const buyUrl = `${urlBase}/api/Product/buy?prodCode=${prodCode}&address=${address}`;

        fetch(buyUrl, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status === 204) {
                    console.log(
                        "BuyDiscountCodeWithSilver response status 204",
                        response.status
                    );

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log(
                        "BuyDiscountCodeWithSilver Response Status:",
                        response.status
                    );
                    throw new Error(
                        "BuyDiscountCodeWithSilver Network response was not ok"
                    );
                }
                return response.json();
            })
            .then((fetchedData: any) => {
                setNewCodeData({
                    prodCode: fetchedData.prodCode, // "prodCode":"punkreward",
                    product: fetchedData.product,
                    code: fetchedData.code,
                    usedBy: fetchedData.usedBy,
                    usedDate: fetchedData.usedDate,
                });
                console.log("fetched buyDiscountCodeWithSilver");
            })
            .catch((error) => {
                console.log(
                    "buyDiscountCodeWithSilver CATCHed an error",
                    error
                );
            });
    };
    const buyDiscountCodeWithSilverTest = (address: any, prodCode: any) => {
        const buyUrl = `${urlBase}/api/Product/buy?prodCode=${prodCode}&address=${address}`;
        console.log("buyUrl:", buyUrl);

        fetch(buyUrl, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                console.log("buyDiscountCode fetch response:", response);

                if (response.status === 204) {
                    console.log(
                        "BuyDiscountCodeWithSilver response status 204",
                        response.status
                    );

                    return null; // no content to parse
                }
                if (!response.ok) {
                    console.log(
                        "BuyDiscountCodeWithSilver Response Status:",
                        response.status
                    );
                    throw new Error(
                        "BuyDiscountCodeWithSilver Network response was not ok"
                    );
                }
                console.log("response.json():", JSON.stringify(response));
                return response.json();
            })
            .then((fetchedData: any) => {
                setNewCodeData({
                    prodCode: fetchedData.prodCode, // "prodCode":"punkreward",
                    product: fetchedData.product,
                    code: fetchedData.code,
                    usedBy: fetchedData.usedBy,
                    usedDate: fetchedData.usedDate,
                });
                console.log("fetched buyDiscountCodeWithSilver");
            })
            .catch((error) => {
                console.log(
                    "buyDiscountCodeWithSilver CATCHed an error",
                    error
                );
            });
    };

    const handleBuyCode = () => {
        buyDiscountCodeWithSilver(
            selectedAccount?.address,
            dataApiInfo?.prodCode
        );
        // buyDiscountCodeWithSilverTest("test", "test");
    };

    const handleCheckCodes = () => {
        if (selectedAccount?.address === undefined) {
            handleConnection();
        }
        apiProductView();
    };

    return (
        <div className="store">
            <div className="store-wrapper">
                <Header />
                <>
                    {/* <div className="store-header">
                    <div className="store-header-wrapper">
                        <div className="store-header-left">
                            <div className="header-logo-wrapper">
                                <div className="header-logo">
                                    <a
                                        href="http://azeropunks.com"
                                        className="link"
                                    >
                                        <img
                                            className="nav-logo"
                                            src={punksLogo}
                                            alt="punks logo"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="store-header-right store-nav-menu-wrapper">
                            <ul className="store-nav-menu">
                                <a
                                    href="http://azeropunks.com"
                                    className="link"
                                >
                                    <li className="store-nav-menu-item">
                                        Home
                                    </li>
                                </a>
                                <a
                                    href="http://a0.artzero.io/collection/5E7HFbvv7o4zFpJxnUsJF5CNktew1T6nh3gctbkSTWGAeiMT?is_for_sale=true"
                                    className="link"
                                >
                                    <li className="store-nav-menu-item">
                                        Marketplace
                                    </li>
                                </a>
                                <a href="#" className="link">
                                    <li className="store-nav-menu-item">
                                        My Account
                                    </li>
                                </a>
                                <a
                                    href={require("../../resources/WP.pdf")}
                                    className="link"
                                >
                                    <li className="store-nav-menu-item">
                                        Whitepaper
                                    </li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div> */}
                </>

                <div className="store-title">
                    <div className="store-title-wrapper">
                        <h2>Get free merch for Silver!</h2>
                        <h5 className="info">pay only for delivery*</h5>
                        <h5 className="info">
                            {accounts.length === 0 ? (
                                <button
                                    onClick={handleConnection}
                                    type="button"
                                    className="btn btn-primary me-2"
                                >
                                    Connect
                                </button>
                            ) : null}
                            {accounts.length > 0 && selectedAccount ? (
                                <>
                                    <div className="small-screen">
                                        <div className="accountTitle">
                                            Account: <br />
                                            {connectedAddressDisplay}
                                        </div>
                                    </div>
                                    <div className="large-screen">
                                        <div className="accountTitle">
                                            Account: <br />
                                            {connectedAddress}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {accounts.length > 0 && !selectedAccount ? (
                                <>
                                    Select an account:{" "}
                                    <select className="accountOption">
                                        {accounts.map((account) => (
                                            <option value={account.address}>
                                                {account.address}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            ) : null}
                        </h5>
                    </div>
                </div>

                <div className="store-content">
                    <div className="store-content-wrapper">
                        {accounts.length === 0 ? null : balanceDisplay ===
                          "undefined silver" ? (
                            <div className="balance-wrapper details">
                                <div className="balance-question">Error…</div>
                                <div className="balance-actual">
                                    Balance is unavailable
                                </div>
                            </div>
                        ) : (
                            <div className="balance-wrapper details">
                                <div className="balance-question">
                                    Your balance:
                                </div>
                                <div className="balance-actual">
                                    {loading ? (
                                        <ReactLoading
                                            type="bars"
                                            color="#03d1b5"
                                            height="10px"
                                            width="25px"
                                        />
                                    ) : null}
                                    {balanceDisplay}
                                </div>
                            </div>
                        )}

                        <div className="store-item">
                            <div className="store-item-wrapper">
                                <div className="item-img-wrapper">
                                    <img
                                        src="https://azeropunks.myshopify.com/cdn/shop/files/440A0D83-49C8-4073-A8DF-BD899640A3C6.jpg?v=1702249785&width=493"
                                        alt=""
                                        className="img"
                                    />
                                </div>
                                <div className="item-name-wrapper">
                                    <p className="item-name">
                                        Azero Punks first reward!
                                        {/* TO DO
                                        ============ pull the name from API with loading animation */}
                                        {/* {itemName ? (   
                                            itemNameDisplay
                                        ) : (
                                            <>
                                                <ReactLoading
                                                    type="bars"
                                                    color="#03d1b5"
                                                    height="10px"
                                                    width="25px"
                                                />
                                            </>
                                        )} */}
                                        {/* 
                                        
                                        
                                        */}
                                    </p>
                                </div>
                                <div className="price-wrapper item-details details">
                                    <p className="price-question">Price: </p>
                                    <p className="price-actual">150 silver</p>
                                    {/* TO DO
                                    ========= pull the price from API  */}
                                    {/* <p className="price-actual">
                                        {itemPrice ? (
                                            itemPriceDisplay
                                        ) : (
                                            <>Not available at the moment</>
                                        )}
                                    </p> */}
                                </div>
                                <div className="available-wrapper item-details details">
                                    <p className="available-question">
                                        Currently available:
                                    </p>
                                    <p className="available-actual">
                                        1 per user
                                    </p>
                                    {/* TO DO 
                                    ============ pull available per user from API */}
                                    {/* <p className="available-actual">
                                        {availableNumber ? (
                                            <>
                                                {availableNumberDisplay} and{" "}
                                                {perUserDisplay}
                                            </>
                                        ) : (
                                            <>ZERO</>
                                        )}
                                    </p> */}
                                </div>

                                {/* buy code */}
                                <div className="buy-code-wrapper">
                                    {discountCode ? (
                                        <>
                                            <div className="details">
                                                <p>
                                                    Your 100% discount code is:
                                                </p>
                                            </div>
                                            <div className="details">
                                                <h5 className="discountCode">
                                                    <b>{discountCodeDisplay}</b>
                                                </h5>
                                            </div>
                                        </>
                                    ) : newCodeData?.detail ? (
                                        <>
                                            <div className="details">
                                                <h5>Not enough Silver</h5>
                                            </div>
                                            {balance !== 0 ? (
                                                <div className="details">
                                                    <p>
                                                        You have{" "}
                                                        {balanceDisplay}
                                                    </p>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <div className="buy-code-btn">
                                            <button
                                                type="button"
                                                className="btn btn-primary me-2"
                                                onClick={handleBuyCode}
                                            >
                                                Buy with silver
                                            </button>
                                        </div>
                                    )}

                                    <p className="buy-code-info info">
                                        *The code discounts only the price of
                                        the item. Delivery charges are to be
                                        paid by the user and the amount depends
                                        on physical location of the reciever and
                                        delivery method. Availability of the
                                        items and availability of the discount
                                        codes are the subject of change. The
                                        code expires within 1 calendar month
                                        from the date of purchase.
                                    </p>
                                </div>
                                <div className="buy-code-wrapper">
                                    {userCode ? (
                                        <div className="details">
                                            <p className="discountCode">
                                                {userCode}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="buy-code-btn">
                                            <button
                                                type="button"
                                                className="btn btn-primary me-2"
                                                onClick={handleCheckCodes}
                                            >
                                                Check my codes
                                            </button>
                                        </div>
                                    )}
                                    {/* {dataApiView ? null : (
                                        <p className="details">
                                            No codes found
                                        </p>
                                    )} */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="next-merch-wrapper">
                            <div className="next-merch-btn">Next merch</div>
                        </div> */}
                    </div>
                </div>

                {/* <StoreContent /> */}
            </div>
            <FooterBootstrap />
        </div>
    );
}

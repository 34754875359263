import React from "react";
// import Logo from "../../../img/logo_self.png";
import LogoWhite from "../../../img/TheLegend/Logo_white_transparent_still.png";
import TheLegendEp1 from "./TheLegendEp1";

interface Props {}

export default function EpisodeContainer({}: Props): React.ReactElement<Props> {
    const videosrc = require("./episode1/ep1.mp4");
    return (
        <div className="episode-container">
            <div className="episode-container-wrapper">
                <div className="episode-header">
                    <div className="episode-header-wrapper">
                        <div className="header-logo header-left">
                            <div className="header-logo-wrapper">
                                <a href="https://azeropunks.com/">
                                    <img
                                        src={LogoWhite}
                                        alt="logo"
                                        className="logo"
                                    />
                                    {/* <img
                                        src={Logo}
                                        alt="logo"
                                        className="logo"
                                    />
                                    AZERO <br /> PUNKS */}
                                </a>
                            </div>
                        </div>
                        <div className="header-music-icon header-right">
                            <img src="#" alt="icon" className="music-icon" />
                        </div>
                    </div>
                </div>

                <div className="episode-content">
                    <div className="episode-content-wrapper">
                        {/* <TheLegendEp1 /> */}
                        <TheLegendEp1 videosrc={videosrc} />
                    </div>
                </div>
            </div>
            <div className="episode-footer">
                <div className="episode-footer-wrapper">
                    <div className="left-side previus-page">PREVIUS PAGE</div>
                    <div className="right-side next-page">NEXT PAGE</div>
                </div>
            </div>
        </div>
    );
}

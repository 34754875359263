import React, { useEffect, useState } from "react";
import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import { WsProvider, ApiPromise } from "@polkadot/api";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";

const wsUrl = "wss://ws.azero.dev";

// const NAME = "GMorDie"; //from tutorial

const NAME = "AzeroPunksRock!";

export default function LoginContainer() {
    const [api, setApi] = useState<ApiPromise>();
    const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
    const [selectedAccount, setSelectedAccount] =
        useState<InjectedAccountWithMeta>();

    const setup = async () => {
        const wsProvider = new WsProvider(wsUrl);

        const api = await ApiPromise.create({
            provider: wsProvider,
        });

        // from MINT.azeropunks.com project
        // const api = await ApiPromise.create({
        //     provider: wsProvider,
        //     noInitWarn: true,
        // });
    };

    const handleConnection = async () => {
        const extensions = await web3Enable(NAME);

        if (!extensions) {
            throw Error("no extensions found");
        }

        const allAccounts = await web3Accounts();

        setAccounts(allAccounts);

        if (allAccounts.length === 1) {
            setSelectedAccount(allAccounts[0]);
        }
    };

    useEffect(() => {
        setup();
    }, []);

    useEffect(() => {
        if (!api) return;

        (async () => {
            const time = await api.query.timestamp.new();

            console.log(time.toHuman());
            // console.log(time.toPrimitive()); // Polkadot uses toPrimitive
        })();
    }, [api]);

    return (
        <div className="login">
            <div className="login-wrapper">
                {accounts.length === 0 ? (
                    <button onClick={handleConnection}>Connect</button>
                ) : null}

                {accounts.length > 0 && selectedAccount ? (
                    selectedAccount.address
                ) : (
                    <>
                        <select>
                            {accounts.map((account) => (
                                <option value={account.address}>
                                    {account.address}
                                </option>
                            ))}
                        </select>
                    </>
                )}
            </div>
        </div>
    );
}
